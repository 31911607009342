import {Module} from '../interfaces/module.interface';

export const PRESENTATION_MODULE: Module = {
	id: 'presentation',
	name: 'Investor Deck',
	layout: {
		directLink: 'investor-deck',
		instance: {
			segments: [
				{
					fields: ['/password']
				}
			]
		}
	},
	schema: {
		properties: {
			id: {type: 'string'},
			password: {type: 'string'}
		}
	}
}