/**
 * Shared public environment config
 */
export const ENV_CONFIG = {
  firebase: {
    "projectId": "bioviva-science",
    "appId": "1:913395859239:web:25718174f503e44b03f302",
    "storageBucket": "bioviva-science.appspot.com",
    "apiKey": "AIzaSyCYcejopBIb04svaiiklokXrJpRpB6Qv8c",
    "authDomain": "bioviva-science.firebaseapp.com",
    "messagingSenderId": "913395859239",
    "measurementId": "G-WV5XRMV3H4"
  }
};
