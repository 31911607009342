import {PipeType} from '../enums/pipe-type.enum';
import {Collections} from '../interfaces/collections';
import {Module} from '../interfaces/module.interface';
import JSX from '../jsx.compiler';
import {CREATED_ON} from './shared/created-on';

export const NEWSLETTER_MODULE: Module = {
  id: Collections.Newsletter,
  name: 'Newsletter',
  layout: {
    editTitleKey: 'email',
    sort: CREATED_ON.sort,
    table: {
			hideEdit: true,
      tableColumns: [
        CREATED_ON.column(),
        {
					key: '/email',
					label: 'EMAIL',
					pipe: [PipeType.Custom, PipeType.Sanitize],
					pipeArguments: {
						0: v => JSX(<a href={`mailto:${v}`}>{v}</a>)
					}
				}
      ]
    }
  },
  schema: {
    properties: {
      id: {type: 'string'},
      name: {type: 'string'},
      ...CREATED_ON.property
    }
  },
  definitions: {
		email: {label: 'EMAIL'},
    ...CREATED_ON.definition()
  },
  metadata: {
    attachedFiles: {
      containes: false
    }
  }
};
